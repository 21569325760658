import {setFieldVisibility} from "../../../apps/KpModule/actions";

const _ = require("lodash");

export const dependencies = [
    {
        name: "FiveMinutesSchedule",
        type: "static",
        fields: [
            {
                path: "id",
                type: "string"
            },
            {
                path: "hours",
                type: "integer"
            },
            {
                path: "minutes",
                type: "integer"
            },
            {
                path: "code",
                f: function() {
                    return _.padStart(this.hours.toString(), 2, "0") + ":" + _.padStart(this.minutes.toString(), 2, "0");
                }
            },
            {
                path: "totalMinutes",
                type: "integer",
                f: function() {
                    return this.hours * 60 + this.minutes;
                }
            }
        ],
        objects: _(24)
            .range()
            .map(hour => _.set({}, "hours", hour))
            .map(obj => _(12)
                .range()
                .map(minMultiplier => _.assign(_.clone(obj), {minutes: 5*minMultiplier}))
                .value())
            .flatten()
            .map(obj => _.assign(obj, {id: _.padStart(obj.hours.toString(), 2, "0") + "h" + _.padStart(obj.minutes.toString(), 2, "0")}))
            .value()
    },

    {
        name: "ImportType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey"
        ],
        objects: [
            {id: "stream", tKey: "stream"},
            {id: "store", tKey: "store"},
            {id: "product", tKey: "product"},
        ]
    },

    {
        name: "FileFormat",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            "tKey",
            "extension",
            "separator",
        ],
        objects: [
            {id: "csv;", tKey: "csvFileFormat", extension: "csv", separator: ";"}
        ]

    },

    {
        name: "ImportFileConf",
        type: "mongoInternal",
        fields: [
            {
                type: "ImportType",
                link: "MTO"
            },
            {path: "stream", type: "InputCorrespondenceByImportType", nullable: true},
            "substring",
            {
                type: "FileFormat",
                link: "MTO"
            }
        ],

        isInTime: function(object, date) {
            return object.hour.hours === date.getHours() && object.hour.minutes <= date.getMinutes() && object.hour.minutes + 5 > date.getMinutes();
        },

        obtainClassifiedFiles: function(importConf, callback) {
            require("fs").readdir("/media/data", (e, files) => {
                if (e) return callback(e);
                const matches = files.reduce(
                    (acc, fileName) => {
                        const fileConf = importConf.filesConfs.find(
                            fileConf => fileName.includes(fileConf.substring) && fileName.endsWith("." + fileConf.fileFormat.extension)
                        );
                        if (fileConf) {
                            return [
                                ...acc,
                                {
                                    path: "/media/data/" + fileName,
                                    name: fileName, fileConf,
                                    format: fileConf.importType.id,
                                    importType: fileConf.importType,
                                    stream: fileConf.stream
                                }
                            ];
                        } else {
                            return acc;
                        }
                    },
                    []
                );

                const matchesByFormat = _.groupBy(matches, "format");

                const matchesByFormatAllTypes = global.app.S.ImportType.objects.reduce(
                    (acc, importType) => Object.assign(acc, {[importType.id]: matchesByFormat[importType.id] || []}),
                    {}
                );

                callback(null, matchesByFormatAllTypes);
            })
        },

        markTheLastOneAsLast: function(objects) {
            if (objects.length > 0) {
                const sortedObjects = _.sortBy(objects, object => -1 * object.totalMinutes);
                sortedObjects[0].last = true;
            }
        },

        sortByTime: function(objects) {
            return _.sortBy(objects, object => object.totalMinutes);
        }
    }
]

export const entity = {
    name: "ImportConf",

    fields: [
        {path: "cc"},
        {
            type: "FiveMinutesSchedule",
            path: "time",
            unique: true,
            link: "MTO"
        },
        {
            path: "responsables",
            type: "User",
            link: "MTM",
            nullable: true
        },
        {
            type: "ImportFileConf",
            path: "filesConfs",
            link: "OTM"
        },
        {path: "autoRun", type: "boolean"}
    ]
}

export const module_ = {
    object: "ImportConf",
    category: {
        path: "dataflow",
        icon: 'database'
    },
    newable: true,
    removable: false,
    viewMap: {
        dt: [
            {path: "time", display: "code", tKey: "processingTime"},
            {path: "responsables", tKey: "mImportConf_usersInCharge"},
            {path: "autoRun"},
        ],
        form: [
            {path: "time", display: "code", tKey: "processingTime"},
            {path: "responsables", filters: ["isInGroupModel"], tKey: "mImportConf_usersInCharge"},

            {
                path: "filesConfs",
                tKey: "fileConfiguration_plural",
                viewMap: {
                    dt: [
                        {path: "importType", display: "tKey", translate: true, tKey: "fileType"},
                        {path: "substring", tKey: "fileIdentifier"}
                    ],
                    form: [
                        {
                            path: "importType",
                            display: "tKey",
                            translate: true,
                            tKey: "fileType",
                            writable: true,
                            subscriptions: {
                                onChange: (newValue, oldValue, { module, store }) => {
                                    store.dispatch(
                                        setFieldVisibility(
                                            "e_stream",
                                            !!newValue && (newValue.id === "stream")
                                        )
                                    )
                                    if(!newValue || newValue.id !== "stream") {
                                        const filesConfsField = module.viewMap.form.fields.find(
                                            field => field.path === 'filesConfs'
                                        )

                                        const streamField = filesConfsField.viewMap.form.fields.find(
                                            field => field.path === 'stream'
                                        )
                                        streamField.setValue(null)
                                    }
                                }
                            }
                        },
                        {path: "stream"},
                        {path: "substring", tKey: "fileIdentifier"},
                        {path: "fileFormat", display: "tKey", translate: true}
                    ]
                }
            },
            {path: "autoRun", tKey: "enableAutoRun", default: false},

        ]
    }
}
